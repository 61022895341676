import React from "react";
import { Box, Center, Container, Link, Heading, Text } from "@chakra-ui/layout";

import { Image } from "@chakra-ui/image";
import ServicingLogo from "../assets/ServicingBranch.png";
import FeeSchedulePDF from "../assets/ServicingFees.pdf";
import TrioFooter from "../components/TrioFooter";

const TrioLegalOverviewPage = () => {
  return (
    <Center mt="30">
      <Box w="75vw" bg="#fff" color="#000" p="12">
        <Image src={ServicingLogo} alt="CBC Logo" w="8vw" />

        <Box color="#fff" bg="#00004d" p="20px" mt="2vh">
          <Heading size="xl">Legal Overview and Licensing</Heading>
        </Box>

        <Container margin="0" maxW="50vw" my="4vh">
          <Text>
            We take consumer protections seriously. Below are some helpful links
            regarding compliance with consumer protection laws.
          </Text>

          <Heading size="lg" mt="4vh" mb="1vh">
            Fee Schedule
          </Heading>
          <Text margin="0 0 3vh 1vw">
            The 
            <Link href={FeeSchedulePDF} textDecoration="underline">
              Fee schedule
            </Link>
             provided is a list of common fees and costs that may be incurred in
            connection with a mortgage loan. Actual fee amounts and costs are
            subject to change and vary depending on the applicable state law
            and/or agency guidelines and your loan documents.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="lg" mt="4vh" mb="1vh">
            Privacy Notice
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Find out how we share your personal information. Our 
            <Link href="/privacy-notice-trio" textDecoration="underline">
              Privacy Notice
            </Link>{" "}
            indicates how Trio Residential, LLC shares your personal information
            in accordance with federal law and state law.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="lg" mt="4vh" mb="1vh">
            Notice of Error / Requests for Information / General Complaints
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Please submit a written notice of error, information request or
            general complaint addressed to us, Attention: resolution unit
            stating the specific error(s) that you believe to have occurred,
            specific information requested or general complaint. Include your
            full name, loan number, and submit to:{" "}
            <Text>1417 North Magnolia Ave</Text>
            <Text>Ocala, Fl 34475</Text>
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="lg" mt="4vh" mb="1vh">
            Texas Disclosure — For property located in Texas
          </Heading>
          <Text margin="0 0 3vh 1vw">
            We are licensed under the laws of the state of Texas and by state
            law are subject to regulatory oversight by the department of savings
            and mortgage lending. Consumers wishing to file a complaint against
            a mortgage banker or a licensed mortgage banker residential mortgage
            loan originator should complete and send a complaint form to the
            Texas department of savings and mortgage lending, 2601 North Lamar,
            Suite 201, Austin, Texas 78705. Complaint forms and instructions may
            be obtained from the department’s website at www.sml.texas.gov. A
            toll-free consumer hotline is available at (877) 276-5550. The
            department maintains a recovery fund to make payments of certain
            actual out-of-pocket damages sustained by borrowers caused by acts
            of licensed mortgage banker residential mortgage loan originators. A
            written application for reimbursement from the recovery fund must be
            filed with and investigated by the department prior to the payment
            of a claim. For more information about the recovery fund, please
            consult the department’s website at www.sml.texas.gov.
          </Text>
          <Text margin="0 0 3vh 1vw">
            We are an Equal Housing Lender. We are committed to fair and
            responsible lending. As prohibited by federal law, we do not engage
            in business practices that discriminate on the basis of race, color,
            religion, national origin, sex, marital status, or age (provided you
            have the capacity to enter into a binding contract). Further, we do
            not discriminate against any applicant or customer on the basis that
            their income derives from any public assistance program or any other
            basis prohibited by law.
          </Text>
        </Container>

        <TrioFooter />
      </Box>
    </Center>
  );
};

export default TrioLegalOverviewPage;
