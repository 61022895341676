import React from "react";
import {
  Box,
  Center,
  Container,
  Divider,
  Heading,
  Text,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import TuleLogo from "../assets/tule.png";
import { Button } from "@chakra-ui/button";
import TRFAFooter from "../components/TRFAFooter";

const MyLinkLoanLandingPage = () => {
  return (
    <Center mt="30">
      <Box w="75vw" bg="#fff" color="#000" p="12">
        <Center mt="3vh" mb="4vh">
          <Image maxW="200px" src={TuleLogo} alt="Tule Logo" />
        </Center>

        <Center mb="10vh">
          <Heading size="4xl">Lease Servicing Center</Heading>
        </Center>

        <Container margin="0" maxW="container.xl" mb="4vh">
          <Heading mb="1vh">Account Access</Heading>
          <Text margin="0 0 3vh 1vw">
            Log in to your account for general information, tax forms,
            documents, payoff requests, statements or making payments.
          </Text>
          <Button
            ml="1vw"
            as="a"
            bg="#00004d"
            color="#fff"
            _hover={{ backgroundColor: "#00009b " }}
            href="https://essexmortgage.estatusconnect.com/User/Login"
          >
            My Lease Login
          </Button>
        </Container>

        <Divider bg="#999" />

        <Container margin="2vh 0 0 0" maxW="container.xl">
          <Heading mb="1vh">Contact Information</Heading>
          <Text fontSize="xl">Lease Contact Information</Text>

          <Box ml="1vw">
            <Box ml="1vw" mb="1vh">
              <Text fontSize="xl">Hours:</Text>
              <Box ml="1vw">
                <Text>Monday - Friday</Text>
                <Text>9:00 am - 7:00 pm ET</Text>
              </Box>
            </Box>

            <Box ml="1vw" mb="1vh">
              <Text fontSize="xl">Email Address:</Text>
              <Box ml="1vw">
                <Text>CustomerCARE@ServicingBranch.com</Text>
              </Box>
            </Box>

            <Box ml="1vw" mb="1vh">
              <Text fontSize="xl">Toll Free Number:</Text>
              <Box ml="1vw">
                <Text>Customer Service: 888.892.0886</Text>
              </Box>
            </Box>

            <Box ml="1vw" mb="1vh">
              <Text fontSize="xl">Payments:</Text>
              <Box ml="1vw">
                <Text>
                  Payments are required to be made electronically. Set up ACH
                  above by clicking on ‘My Lease Login’.
                </Text>
              </Box>
            </Box>

            <Box ml="1vw" mb="1vh">
              <Text fontSize="xl">Qualified Written Requests:</Text>
              <Box ml="1vw">
                <Text mb="2vh">
                  Qualified Written Requests are required to be submitted to the
                  address listed below. When Tule River Finance Authority is in
                  receipt of a Qualified Written Request (as defined by RESPA),
                  an acknowledgment notice is sent within five (5) business
                  days. We will respond to you within thirty (30) business days
                  of receipt of your written notice, unless otherwise defined by
                  RESPA guidelines.
                </Text>
                <Text>1417 North Magnolia Ave</Text>
                <Text>Ocala, Fl 34475</Text>
              </Box>
            </Box>
          </Box>
        </Container>
        <TRFAFooter />
      </Box>
    </Center>
  );
};

export default MyLinkLoanLandingPage;
