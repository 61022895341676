import React from "react";
import { Center, Text, Link } from "@chakra-ui/layout";

const TRFAFooter = () => {
  return (
    <Center mt="10vh">
      <Text>
        <Link href="/legal-overview-trfa" borderRight="1px solid" pr="15px">
          Legal Overview and Licensing
        </Link>
        <Link href="/privacy-notice-trfa" px="15px" borderRight="1px solid">
          Website Privacy Policy
        </Link>
        <Link href="/ca-privacy-policy-trfa" pl="15px">
          CA Privacy Policy
        </Link>
      </Text>
    </Center>
  );
};

export default TRFAFooter;
