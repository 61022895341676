import React from "react";
import {
  Box,
  Center,
  Container,
  Heading,
  Text,
  OrderedList,
  UnorderedList,
  ListItem,
} from "@chakra-ui/layout";

import { Image } from "@chakra-ui/image";
import ServicingLogo from "../assets/ServicingBranch.png";
import TrioFooter from "../components/TrioFooter";

const TrioCAPrivacyPolicyPage = () => {
  return (
    <Center mt="30">
      <Box w="75vw" bg="#fff" color="#000" p="12">
        <Image src={ServicingLogo} alt="CBC Logo" w="8vw" />

        <Box color="#fff" bg="#00004d" p="20px" mt="2vh">
          <Heading size="xl">California Privacy Policy</Heading>
        </Box>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            CCPA Privacy Notice for California Residents
          </Heading>
          <Text margin="0 0 3vh 1vw">
            This privacy notice supplements the existing Privacy Policy of Trio
            Residential, LLC, and applies solely to residents of the State of
            California (herein referred to as “the consumer” or “you”).
          </Text>
          <Text margin="0 0 3vh 1vw">
            The CCPA provides specific rights regarding your personal
            information. These rights include:
            <UnorderedList>
              <ListItem>
                The right to know what personal information Tule River Finance
                Authority collects;
              </ListItem>
              <ListItem>
                The right for you to request a copy of this personal
                information;
              </ListItem>
              <ListItem>The right to delete this information;</ListItem>{" "}
              <ListItem>
                The right to request corrections of your information;
              </ListItem>
              <ListItem>
                The right to opt-out of the transfer or sale of your
                information; and{" "}
              </ListItem>
              <ListItem>
                The right to not be discriminated against if you exercise any of
                these rights.
              </ListItem>
            </UnorderedList>
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Types of Information We May Collect
          </Heading>
          <Text margin="0 0 3vh 1vw">
            <UnorderedList>
              <ListItem>
                Personal identifiers such as your name, aliases, address, IP
                address, email address, phone number, social security number,
                driver’s license or other similar identifiers
              </ListItem>
              <ListItem>
                Financial information such as bank statements, account numbers,
                and asset information or documentation
              </ListItem>
              <ListItem>
                Credit information such as credit reports, inquiry information,
                existing and past creditor information, current balances, and
                payment histories
              </ListItem>
              <ListItem>
                Employment and income information such as name and address of
                your employer, social security or disability income, years
                worked, paystubs, W2s, 1099s, 1120s, child support or alimony
                information
              </ListItem>
              <ListItem>
                Government-required demographic information including race,
                ethnicity, and gender
              </ListItem>
              <ListItem>
                Information on your property, such as address, type of dwelling,
                and other appraisal-related information
              </ListItem>
              <ListItem>
                Educational information such as degrees or transcripts{" "}
              </ListItem>
              <ListItem>
                Personal information does not include publicly available
                information or consumer information that is de-identified or
                aggregated
              </ListItem>
            </UnorderedList>
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            How We Receive Your Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            <UnorderedList>
              <ListItem>
                Directly or indirectly from our clients or their agents –
                including, but not limited to interactions on our website, loan
                applications or documents provided related to the mortgage loan
                process;
              </ListItem>
              <ListItem>
                From third parties that we may interact with during the course
                of providing services to you.
              </ListItem>
            </UnorderedList>
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            How We Use Your Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Trio Residential, LLC, is not in the business of selling your
            personal information. We do not have affiliates. We generally use
            your information for the purpose of originating and servicing
            residential mortgage loans. We may use or disclose the personal
            information we collect for one or more of the following business
            purposes:
          </Text>
          <Text margin="0 0 3vh 1vw">
            <UnorderedList>
              <ListItem>
                To provide you with information you have requested regarding our
                products and services;
              </ListItem>
              <ListItem>
                To originate, process, underwrite, and fund residential mortgage
                loans;
              </ListItem>
              <ListItem>
                To our third-party service providers in connection with
                originating your loan; such as appraisers, credit reporting
                agencies, and settlement and title companies;
              </ListItem>
              <ListItem>
                To fulfill legal obligations, such as court orders or subpoenas;
              </ListItem>
              <ListItem>
                To federal and state regulators who supervise our mortgage
                lending activities;
              </ListItem>
              <ListItem>
                To other financial institutions that may purchase your loan,
                collect your payments, or service your loan; and
              </ListItem>
              <ListItem>For advertising and marketing purposes.</ListItem>
            </UnorderedList>
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Your Right to Request Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            You have a right to request that we disclose the categories of
            information we have collected and to request a copy of your specific
            personal information. This information will be delivered to you by
            the method of your preference including through your existing
            account, via mail, or electronically in a portable format. This
            information will be provided to you in a format that will be readily
            usable for you to easily transmit the information from one entity to
            the other. Please note that we are not able to disclose Social
            Security numbers, driver’s license numbers or other
            government-issued identifiers, financial account numbers, health
            insurance or medical identification numbers, account passwords, or
            security questions and answers. We also cannot provide information
            if it creates a substantial risk to the security of your
            information.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Your Right to Request Deletion
          </Heading>
          <Text margin="0 0 3vh 1vw">
            You have the right to request that Trio Residential, LLC, delete any
            of the personal information we collect from you. Please note that
            there may be instances in which we cannot delete your personal
            information. The majority of the information that we collect is
            governed by the Gramm-Leach-Bliley Act (GLBA) and other federal and
            state laws. If we are not able to delete your information, we will
            respond to your request with further explanation.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Your Right to Request Information Correction
          </Heading>
          <Text margin="0 0 3vh 1vw">
            You have the right to request that we correct any personal
            information we have on file for you. We reserve the right to confirm
            your identity before making corrections and may request additional
            information to support your correction request. We may deny your
            request if we are unable to adequately document that the original
            information was inaccurate. If we can correct your information, we
            will provide written confirmation. If we are not able to correct
            your information, we will respond to your request with further
            explanation.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            How to Make a Request Regarding Your Personal Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            You have three (3) ways to submit requests to Tule River Finance
            Authority:
            <OrderedList>
              <ListItem>
                By calling our toll-free number at: 888.892.0887.
              </ListItem>
              <ListItem>
                By submitting a request in writing by mail to our office: Tule
                River Finance Authority Compliance Department 1417 North
                Magnolia Ave Ocala, Fl 34475
              </ListItem>
              <ListItem>By emailing CustomerCARE@ServicingBranch.com</ListItem>
            </OrderedList>
          </Text>
          <Text margin="0 0 3vh 1vw">
            If submitting a request in writing, please state that you are making
            a request under the California Consumer Privacy Act (or CCPA) and
            provide contact information so that we may respond to your request.
            We will confirm receipt of your request within ten (10) business
            days of receipt. We will make every effort to respond to or fulfill
            your request within forty-five (45) business days from the receipt
            of your request. If we need more time to fulfill your request, you
            will be notified.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Safeguarding Your Personal Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Please note that safeguarding your personal information is of utmost
            importance to us, and we will verify your identity using at least
            three (3) pieces of information that we have on file for you before
            releasing or deleting any personal information. We may deny your
            request if we are not able to verify your identity. You may
            designate an authorized agent to act on your behalf. You must
            provide written permission that this person may act on your behalf,
            and they must be properly registered with the Secretary of State. We
            will verify the identity of your authorized agent before releasing
            or deleting your personal information.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Minors Under 16 Years of Age
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Trio Residential, LLC, does not collect or maintain personal
            information of children under the age of sixteen (16).
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Questions?
          </Heading>
          <Text margin="0 0 3vh 1vw">
            If you have any questions related to this privacy notice, please
            contact us by e-mailing CustomerCARE@ServicingBranch.com
          </Text>
        </Container>

        <TrioFooter />
      </Box>
    </Center>
  );
};

export default TrioCAPrivacyPolicyPage;
