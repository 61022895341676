import React from "react";
import { Center, Text, Link } from "@chakra-ui/layout";

const TrioFooter = () => {
  return (
    <Center mt="10vh">
      <Text>
        <Link href="/legal-overview-trio" borderRight="1px solid" pr="15px">
          Legal Overview and Licensing
        </Link>
        <Link href="/privacy-notice-trio" px="15px" borderRight="1px solid">
          Website Privacy Policy
        </Link>
        <Link href="/ca-privacy-policy-trio" pl="15px">
          CA Privacy Policy
        </Link>
      </Text>
    </Center>
  );
};

export default TrioFooter;
