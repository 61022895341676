import React from "react";
import { Box, Center, Container, Link, Heading, Text } from "@chakra-ui/layout";

import { Image } from "@chakra-ui/image";
import ServicingLogo from "../assets/ServicingBranch.png";
import TrioFooter from "../components/TrioFooter";

const TrioPrivacyNoticePage = () => {
  return (
    <Center mt="30">
      <Box w="75vw" bg="#fff" color="#000" p="12">
        <Image src={ServicingLogo} alt="CBC Logo" w="8vw" />

        <Box color="#fff" bg="#00004d" p="20px" mt="2vh">
          <Heading size="xl">Privacy Notice</Heading>
        </Box>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="lg" mt="4vh" mb="1vh">
            Your privacy is important to us.
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Trio Residential, LLC is committed to safeguarding the personal
            information of our customers. This privacy policy has been developed
            for compliance with the Gramm-Leach-Bliley Act, as well as various
            federal and state laws that relate to the protection of consumer
            privacy. This policy describes the types of information we collect,
            who has access to that information, and how we keep your information
            safe from unauthorized access or use.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Types of Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            The types of personal information we collect and share depend on the
            product or service you have with us. This information can include
            but is not limited to: your name, address, phone number or e-mail
            address, social security number, account balances, credit history
            and score, income, or payment history.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Collection of Personal Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            We collect personal information from you when you fill out an
            application on our website, utilize the “Contact” section on our
            website, or fill out the necessary forms and disclosures to process
            a loan application with us. We may use other sources to collect
            information in order to process your loan application such as credit
            reporting bureaus, title insurers, insurance companies, banks,
            attorneys and employers.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Sharing of Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            We do not sell, distribute, or otherwise disclose your personal
            information to anyone except as permitted by law or as otherwise
            disclosed in this Privacy Policy. Trio Residential, LLC does not
            have affiliates. Trio Residential, LLC shares your personal
            information for our everyday business purposes – such as to process
            and underwrite your loan application, maintain your account, respond
            to court orders and legal investigations, or report to credit
            bureaus. We may transfer/sell your loan to a secondary market
            investor. You will be notified of this transfer in writing. We limit
            the sharing of your information whenever possible; however, we may
            share your information with certain non-affiliated third parties in
            the normal course of business. We make every effort to ensure that
            these companies have strict privacy policies in place. Some examples
            of these non-affiliated parties include, but are not limited to a)
            investors, b) credit reporting agencies, c) third-party servicing
            agents, d) non-affiliated compliance or quality control firms, and
            e) non-affiliated legal firms.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Security of Information
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Trio Residential, LLC takes the confidentiality of your personal
            information very seriously. Our company has adopted multiple
            policies and procedures designed to safeguard your information from
            unauthorized access or use. These include building security measures
            and information security measures such as password protection,
            firewalls, and anti-virus software. Our company policy only
            authorizes employees to access your personal information when they
            have a business purpose for doing so. We require all employees to
            adhere to strict confidentiality requirements when working with the
            personal information of our customers.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Use of Cookies and Google Analytics
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Our website utilizes cookies, which are small pieces of information
            stored on your computer’s hard drive. These cookies are not used to
            obtain personally-identifying information, but rather to help us
            make customization and navigation of our site easier for our
            customers. You may opt to adjust your browser settings to disable
            cookies. Please note that if you opt to do this, you may be unable
            to use some features of our site. Our website utilizes Google
            Analytics. For further information on this service and how they
            collect and process data, please visit{" "}
            <Link
              href="https://www.google.com/policies/privacy/partners/"
              textDecoration="underline"
            >
              https://www.google.com/policies/privacy/partners/
            </Link>
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Outside Links
          </Heading>
          <Text margin="0 0 3vh 1vw">
            We may opt to include third-party links on this website for your
            convenience. These sites are not maintained by or under the control
            of Trio Residential, LLC, therefore we have no liability for the
            content of these linked sites. We encourage you to review the
            privacy policies of any third-party site you navigate to when you
            leave our site. We always welcome your feedback, so if you have
            concerns about any link that you see on this website, please contact
            us.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Children’s Online Privacy Protection Act
          </Heading>
          <Text margin="0 0 3vh 1vw">
            Due to the financial nature of our business, this website is
            directed to adults. In compliance with the requirements of COPPA
            (Children’s Online Privacy Protection Act), we do not knowingly
            collect or maintain any information from anyone under 13 years of
            age. If our company becomes aware that information has been
            collected from or submitted by a child under the age of 13, we will
            delete that information. If you are a parent or guardian who is
            concerned that a child under 13 has provided us with personally
            identifiable information, please contact us directly to ensure that
            the information is deleted.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Your Consent
          </Heading>
          <Text margin="0 0 3vh 1vw">
            By using this site, you consent to this Privacy Policy.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            Changes to Our Privacy Policy
          </Heading>
          <Text margin="0 0 3vh 1vw">
            We may update or revise this policy as needed. The date below shall
            be updated when an update is made. This policy was last modified on
            March 28, 2018.
          </Text>
        </Container>

        <Container margin="0" maxW="50vw" my="4vh">
          <Heading size="md" mt="4vh" mb="1vh">
            How to Contact Us
          </Heading>
          <Text margin="0 0 3vh 1vw">
            If there are any questions or concerns regarding this privacy
            policy, you may contact us using the information below.
          </Text>
          <Text margin="0 0 0 1vw">Trio Residential, LLC</Text>

          <Text margin="0 0 0 1vw">Attn: Compliance Department</Text>

          <Text margin="0 0 3vh 1vw">
            <Text>1417 North Magnolia Ave</Text>
            <Text>Ocala, Fl 34475</Text>
          </Text>

          <Text margin="0 0 0 1vw">888.892.0887</Text>

          <Text margin="0 0 0 1vw">
            Website: www.servicingbranch.com/mylinkloan
          </Text>
          <Text margin="0 0 0 1vw">
            Email: CustomerCARE@ServicingBranch.com
          </Text>
        </Container>

        <TrioFooter />
      </Box>
    </Center>
  );
};

export default TrioPrivacyNoticePage;
