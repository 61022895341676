import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import MyLinkLoanLandingPage from "./pages/MyLinkLoanLandingPage";
import TrioLegalOverviewPage from "./pages/TrioLegalOverviewPage";
import TrioPrivacyNoticePage from "./pages/TrioPrivacyNoticePage";
import TrioCAPrivacyPolicyPage from "./pages/TrioCAPrivacyPolicyPage";
import TRFALandingPage from "./pages/TRFALandingPage";
import TRFALegalOverviewPage from "./pages/TRFALegalOverviewPage";
import TRFAPrivacyNoticePage from "./pages/TRFAPrivacyNoticePage";
import TRFACAPrivacyPolicyPage from "./pages/TRFACAPrivacyPolicyPage";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/mylinkloan" element={<MyLinkLoanLandingPage />} />
      <Route path="/legal-overview-trio" element={<TrioLegalOverviewPage />} />
      <Route path="/privacy-notice-trio" element={<TrioPrivacyNoticePage />} />
      <Route
        path="/ca-privacy-policy-trio"
        element={<TrioCAPrivacyPolicyPage />}
      />
      <Route path="/trfa" element={<TRFALandingPage />} />
      <Route path="/legal-overview-trfa" element={<TRFALegalOverviewPage />} />
      <Route path="/privacy-notice-trfa" element={<TRFAPrivacyNoticePage />} />
      <Route
        path="/ca-privacy-policy-trfa"
        element={<TRFACAPrivacyPolicyPage />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
