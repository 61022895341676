import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.location.href =
      "https://essexmortgage.estatusconnect.com/User/Login";
  }, []);

  return null;
};

export default Home;
